import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import ArdsleyTitle from "../components/ArdsleyTitle";
// import './features.css'

function chunk(array, chunkSize) {
  return [].concat.apply([],
    array.map(function (elem, i) {
      return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
    })
  );
}


export const FeaturesPageTemplate = ({ content, title }) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">

              <div className="columns">
                <div className="column">
                  <ArdsleyTitle text={title} />
                  <HTMLContent content={content} />
                  <br />
                  <div className="columns is-mobile">
                    <div className="column is-6">
                      <img src="/img/smokefree.png" />
                      <p>Smoke-Free</p>
                    </div>
                    <div className="column is-6">
                      <img src="/img/pet-friendly.png" />
                      <p>Pet-Friendly</p>
                    </div>
                  </div>

                  <div className="markdown" style={{ paddingTop: '10px' }}>
                    <h2>Pet Policy</h2>

                    <p>With a natural Pet Walk, Ardsley is pet-friendly. Size, breed and location restrictions may apply. Cats are always welcome.</p>
                    {/* <a href="/inquire">Please tell us your must-haves. We’ll strive to meet your needs.</a> */}
                  </div>
                </div>
                <div className="column">
                  <img src="/img/bathroom.jpg" style={{ width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {chunk(features, 4).map((row, idx) => (
          <div key={idx} className="columns" style={{ marginBottom: '18px' }}>
            {
              row.map(({ name }) => (
                <div className="column" style={{ borderRadius: '10px', backgroundColor: 'rgb(230 244 255)', textAlign: 'center', padding: "10px", margin: '5px' }}>
                  <p key={name} style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'black' }}>{name}</p>
                </div>
              ))
            }
          </div>
        ))
        } */}
      </div>
    </section>
  );
};

const FeaturesPage = ({ data }) => {
  const {
    frontmatter: { image, title },
    html,
  } = data.markdownRemark;

  return (
    <Layout>
      <FeaturesPageTemplate
        image={image}
        content={html}
        title={title}
      />
    </Layout>
  );
};

FeaturesPage.propTypes = {
  data: PropTypes.object,
};

export default FeaturesPage;

export const featuresnPageQuery = graphql`
  query featuresPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
